import { Font } from '@components/atoms';
import { medicineGenericCodeKoreanUnit } from '@components/organisms/managementMedicineTable/config/domain';
import { priceFormatter } from '@functions/formatters';
import { ManagedMedicineItemType } from '@services/myDoctor/types';
import { isNil } from 'lodash';
import * as Style from './index.style';

interface MedicineUnitPriceProps {
  selectedMedicine: ManagedMedicineItemType | null;
  medicineUnitPrice: string;
  medicinePriceValidationMessage: string;
  handleChangeMedicineUnitPrice: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
}

function MedicineUnitPrice({
  selectedMedicine,
  medicineUnitPrice,
  medicinePriceValidationMessage,
  handleChangeMedicineUnitPrice,
}: MedicineUnitPriceProps) {
  return (
    <Style.ModalBodyContentArea>
      <Style.TitleArea>
        <Font fontType="body1_medium" color="fill/black">
          약가
        </Font>
        <Font fontType="body1_medium" color="state/distructive">
          *
        </Font>
      </Style.TitleArea>

      <Style.MedicineUnitPriceArea>
        <Style.MedicineUnitPriceInputArea>
          {selectedMedicine && (
            <Style.MedicineUnitArea>
              <Font fontType="body1" color="fill/black">
                {`1${
                  medicineGenericCodeKoreanUnit[
                    selectedMedicine.generic_name_code
                  ]
                }당`}
              </Font>
            </Style.MedicineUnitArea>
          )}

          <Style.MedicineSearchInput
            placeholder="숫자만 입력해주세요"
            value={priceFormatter.commaFormatter(
              isNil(medicineUnitPrice) ? '' : medicineUnitPrice,
            )}
            disabled={false}
            isError={Boolean(medicinePriceValidationMessage)}
            onChange={(e) => {
              handleChangeMedicineUnitPrice(e);
            }}
          />

          <Font fontType="body1_medium" color="fill/black">
            원
          </Font>
        </Style.MedicineUnitPriceInputArea>

        {medicinePriceValidationMessage && (
          <Style.InputErrorArea>
            <Font fontType="body2" color="state/distructive">
              {medicinePriceValidationMessage}
            </Font>
          </Style.InputErrorArea>
        )}
      </Style.MedicineUnitPriceArea>
    </Style.ModalBodyContentArea>
  );
}

export default MedicineUnitPrice;
