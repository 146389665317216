import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import BModal from '@components/meraki-ui/BModal';
import {
  medicineCategoryList,
  MedicineCategoryType,
  medicineCompoundingFeeConfig,
  MedicineCompoundingFeeConfigValueType,
  medicineGenericCodeUnit,
  medicineGenericNameCodePriceLimit,
  medicineNameConfig,
} from '@components/organisms/managementMedicineTable/config/domain';
import { getManagementMedicineListCreatedAfterDate } from '@components/organisms/managementMedicineTableV2';
import { commonHooks } from '@hooks';
import { myDoctorAPI } from '@services/myDoctor';
import { ManagedMedicineItemType } from '@services/myDoctor/types';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  getManagementMedicineList,
  selectMedicineManagementList,
} from '@stores/telepharmacyStore/telepharmacyList';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import { isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import {
  MedicineCategory,
  MedicineCompoundingFee,
  MedicineSearch,
  MedicineUnitPrice,
  PaymentPriceExpect,
} from './components';
import useMedicineUnitManagementModalHook from './hooks';
import * as Style from './index.style';
import { MEDICINE_PRICE_VALIDATION_MESSAGES } from './utils/constants';

export interface SelectedMedicineListFilteredByMasterCode
  extends ManagedMedicineItemType {
  price?: number | null;
  isDropDownOpen?: boolean;
  isAlreadyExist?: boolean;
}

function MedicineUnitManagementModal() {
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);
  const {
    isVisible,
    closeModal: closeRegisterModal,
    selectedCategory,
  } = useMedicineUnitManagementModalHook();
  const managementMedicineList = useAppSelector(selectMedicineManagementList);

  const [selectedMedicineCategory, setSelectedMedicineCategory] =
    useState<MedicineCategoryType>(
      { id: 0, label: '전체', codes: [] }, // 전체면 null 선택되었으면 해당 카테고리 선택
    );
  const [selectedMedicine, setSelectedMedicine] =
    useState<ManagedMedicineItemType | null>(null);
  const [medicineSearchInputValue, setMedicineSearchInputValue] = useState('');
  const [isShowMedicineUnitPrice, setIsShowMedicineUnitPrice] = useState(false);
  const [medicineUnitPrice, setMedicineUnitPrice] = useState<string>('');
  const [isMedicineSumInputAreaFocus, setIsMedicineSumInputAreaFocus] =
    useState(false);
  const [hasCompoundingFee, setHasCompoundingFee] = useState<boolean | null>(
    null,
  );
  const [medicineCompoundingFees, setMedicineCompoundingFees] = useState<
    MedicineCompoundingFeeConfigValueType[] | null
  >(null);
  const [isDisabledAddMedicineButton, setIsDisabledAddMedicineButton] =
    useState(true);
  const [medicinePriceValidationMessage, setMedicinePriceValidationMessage] =
    useState('');

  const handleChangeMedicineUnitPrice = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = e.target.value;
    const filteredValue = inputValue.replace(/[^0-9]/g, '');
    const isEmpty = filteredValue === '';
    if (filteredValue.length > 9) return;

    if (selectedMedicine?.generic_name_code) {
      const priceLimit =
        medicineGenericNameCodePriceLimit[selectedMedicine.generic_name_code];

      if (priceLimit > parseInt(filteredValue, 10)) {
        setMedicinePriceValidationMessage(
          MEDICINE_PRICE_VALIDATION_MESSAGES.MIN_PRICE_ERROR(priceLimit),
        );
      } else {
        setMedicinePriceValidationMessage('');
      }
    }

    setMedicineUnitPrice(isEmpty ? '' : String(parseInt(filteredValue, 10)));
  };

  const handleChangeMedicineSumInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    quantity: number,
  ) => {
    if (!medicineCompoundingFees) return;
    const inputValue = e.target.value;
    const filteredValue = inputValue.replace(/[^0-9]/g, '');
    const isEmpty = filteredValue === '';
    if (filteredValue.length > 9) return;

    setMedicineCompoundingFees((prev) => {
      return prev!.map((item) =>
        item.quantity === quantity
          ? {
              ...item,
              price: isEmpty ? null : parseInt(filteredValue, 10),
            }
          : item,
      );
    });
  };

  const init = () => {
    setMedicineSearchInputValue('');
    setIsShowMedicineUnitPrice(false);
    setMedicineUnitPrice('');
    setSelectedMedicine(null);
    setIsMedicineSumInputAreaFocus(false);
    setMedicineCompoundingFees(null);
    setHasCompoundingFee(null);
    setMedicinePriceValidationMessage('');
  };

  const handleClickMedicineCategory = (medicine: MedicineCategoryType) => {
    setMedicineSearchInputValue('');
    setIsShowMedicineUnitPrice(false);
    setMedicineUnitPrice('');
    setSelectedMedicine(null);
    setIsMedicineSumInputAreaFocus(true);
    setMedicineCompoundingFees(null);
    setHasCompoundingFee(null);
    setSelectedMedicineCategory(medicine);
  };

  const { useQuery } = commonHooks;
  const query = useQuery();
  const dispatch = useAppDispatch();
  const [limit, setLimit] = useState<number>(200);
  const [isLoading, setIsLoading] = useState(false);

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;

  const handleChangeMedicineSearchInput = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = e.target.value;
    setMedicineSearchInputValue(inputValue);
  };

  const handleClickSearchMedicineReset = () => {
    setSelectedMedicine(null);
    setIsShowMedicineUnitPrice(false);
    setMedicineCompoundingFees(null);
    setHasCompoundingFee(null);
    setMedicineUnitPrice('');
    setMedicineSearchInputValue('');
  };

  const handleAddMedicine = async () => {
    if (!selectedMedicine) return;
    if (!medicineCompoundingFees) return;
    // if (isLoading) return;

    setIsLoading(true);

    try {
      const postManagedMedicineParam = {
        pharmacy_medicine: {
          standard_code: selectedMedicine.standard_code,
          name: selectedMedicine.korean_product_name || '',
          is_active: true,
          master_code: selectedMedicine.master_code,
          generic_name_code: selectedMedicine.generic_name_code,
          pharmacy: String(pharmacist.pharmacy_id) || '',
        },
        unit: medicineGenericCodeUnit[selectedMedicine.generic_name_code],
        quantity: '1',
        price: Number(medicineUnitPrice),
        is_deleted: false,
        extra: hasCompoundingFee
          ? {
              preparation_fee: medicineCompoundingFees,
            }
          : {},
      };

      await myDoctorAPI
        .postManagementMedicineListV2(postManagedMedicineParam)
        .then(() => {
          dispatch(
            getManagementMedicineList({
              id: pharmacist.pharmacy_id || 0,
              offset: page * limit,
              limit,
            }),
          );
        })
        .then(() => {
          setIsLoading(false);
          closeRegisterModal();
        });
    } catch (e) {
      console.log('handleAddMedicine error', e);
    }
  };

  const handleSelectMedicine = (medicine: ManagedMedicineItemType) => {
    if (!managementMedicineList) return;

    const medicineListResponseFilteredByMasterCode = managementMedicineList
      .filter((managementMedicine) =>
        getManagementMedicineListCreatedAfterDate(managementMedicine),
      )
      .filter(
        (managementMedicine) =>
          managementMedicine.pharmacy_medicine.master_code ===
          medicine.master_code,
      );

    if (medicineListResponseFilteredByMasterCode?.length === 1) {
      alert(
        `${
          medicineNameConfig[medicine.standard_code]
        }의 단위별 가격을 수정하시려면 표에서 [수정] 버튼을 눌러 수정해주세요.`,
      );

      return;
    }

    setSelectedMedicine(medicine);
    setMedicineSearchInputValue(
      `(${medicine.master_code}) ${medicine.korean_product_name}`,
    );
    setIsShowMedicineUnitPrice(true);
  };

  useEffect(() => {
    if (selectedMedicineCategory.label && selectedMedicine) {
      setMedicineCompoundingFees(
        medicineCompoundingFeeConfig[selectedMedicineCategory.label],
      );
    }
  }, [selectedMedicineCategory, selectedMedicine]);

  useEffect(() => {
    init();
    if (selectedCategory) {
      const selectedMedicineCategoryAtTable = medicineCategoryList.find(
        (medicineCategory) => medicineCategory.label === selectedCategory,
      );

      if (selectedMedicineCategoryAtTable) {
        setSelectedMedicineCategory(selectedMedicineCategoryAtTable);
        return;
      }
    }
    setSelectedMedicineCategory({ id: 0, label: '전체', codes: [] });
  }, [isVisible, selectedCategory]);

  const isMedicineAddButtonActive = useMemo(() => {
    if (!selectedMedicine) return false;

    const isUnitPriceSet =
      Boolean(medicineUnitPrice) && Number(medicineUnitPrice) > 0;
    const isCompoundingFeeValid =
      hasCompoundingFee === false ||
      (hasCompoundingFee === true &&
        medicineCompoundingFees &&
        medicineCompoundingFees.every((fee) => !isNil(fee.price)));
    const priceLimit =
      medicineGenericNameCodePriceLimit[selectedMedicine.generic_name_code];
    const isPriceLimitValid = Number(priceLimit) <= Number(medicineUnitPrice);

    return isUnitPriceSet && isCompoundingFeeValid && isPriceLimitValid;
  }, [
    hasCompoundingFee,
    medicineCompoundingFees,
    medicineUnitPrice,
    selectedMedicine,
  ]);

  useEffect(() => {
    if (isMedicineAddButtonActive) {
      setIsDisabledAddMedicineButton(false);
    } else {
      setIsDisabledAddMedicineButton(true);
    }
  }, [isMedicineAddButtonActive]);

  const isShowMedicineUnitPriceAndCompoundingFee =
    hasCompoundingFee &&
    medicineCompoundingFees &&
    medicineCompoundingFees.length > 4;

  return (
    <BModal
      open={isVisible}
      width={960}
      padding={30}
      onOpenChange={() => {
        init();
        closeRegisterModal();
      }}
    >
      <Style.ModalArea>
        <Style.TitleArea>
          <Style.TitleContentArea>
            <Font fontType="display2" color="fill/black">
              비급여 의약품 정보 추가
            </Font>
            <Style.TitleContentNoticeArea>
              <SvgIcon
                icon="infoIcon"
                color="fill/medium"
                width={16}
                height={16}
              />
              <Font fontType="body2" color="fill/medium">
                의약품 정보 입력 후 [추가하기] 버튼을 클릭하셔야 최종
                반영됩니다.
              </Font>
            </Style.TitleContentNoticeArea>
          </Style.TitleContentArea>

          <Style.TitleButtonsArea>
            <Button
              padding="16px 66px"
              borderRadius="12px"
              borderColor={COLOR['border/outline']}
              backgroundColor={COLOR['fill/white']}
              title={
                <Font fontType="body1" color="fill/dark">
                  취소
                </Font>
              }
              onClick={closeRegisterModal}
            />
            <Button
              padding="16px 66px"
              borderRadius="12px"
              backgroundColor={
                isDisabledAddMedicineButton
                  ? COLOR['disabled/background']
                  : COLOR['fill/accent']
              }
              title={
                <Font
                  fontType="body1"
                  color={
                    isDisabledAddMedicineButton
                      ? 'disabled/foreground'
                      : 'fill/white'
                  }
                >
                  추가하기
                </Font>
              }
              onClick={handleAddMedicine}
              disabled={isDisabledAddMedicineButton}
            />
          </Style.TitleButtonsArea>
        </Style.TitleArea>

        <Style.ModalBodyArea>
          <Style.ModalLeftBodyArea
            shouldMorePadding={isShowMedicineUnitPriceAndCompoundingFee}
            medicineCompoundingFeesLength={medicineCompoundingFees?.length}
          >
            <Style.ModalLeftBodyTitleArea>
              <Font fontType="h2" color="fill/black">
                의약품 선택
              </Font>
            </Style.ModalLeftBodyTitleArea>

            <Style.ModalLeftContentArea>
              <MedicineCategory
                selectedMedicineCategory={selectedMedicineCategory}
                handleClickMedicineCategory={handleClickMedicineCategory}
              />

              {selectedMedicineCategory.label !== '전체' && (
                <MedicineSearch
                  selectedMedicineCategory={selectedMedicineCategory}
                  medicineSearchInputValue={medicineSearchInputValue}
                  selectedMedicine={selectedMedicine}
                  handleChangeMedicineSearchInput={
                    handleChangeMedicineSearchInput
                  }
                  handleSelectMedicine={handleSelectMedicine}
                  handleClickSearchMedicineReset={
                    handleClickSearchMedicineReset
                  }
                />
              )}

              {isShowMedicineUnitPrice && (
                <MedicineUnitPrice
                  selectedMedicine={selectedMedicine}
                  medicineUnitPrice={medicineUnitPrice}
                  medicinePriceValidationMessage={
                    medicinePriceValidationMessage
                  }
                  handleChangeMedicineUnitPrice={handleChangeMedicineUnitPrice}
                />
              )}

              {isShowMedicineUnitPrice && (
                <MedicineCompoundingFee
                  hasCompoundingFee={hasCompoundingFee}
                  isMedicineSumInputAreaFocus={isMedicineSumInputAreaFocus}
                  selectedMedicine={selectedMedicine}
                  medicineCompoundingFees={medicineCompoundingFees}
                  setHasCompoundingFee={setHasCompoundingFee}
                  handleChangeMedicineSumInput={handleChangeMedicineSumInput}
                />
              )}
            </Style.ModalLeftContentArea>
          </Style.ModalLeftBodyArea>

          <Style.ModalRightBodyArea>
            <PaymentPriceExpect
              isShowPaymentPriceExpectArea={isMedicineAddButtonActive}
              hasCompoundingFee={hasCompoundingFee}
              medicineUnitPrice={medicineUnitPrice}
              selectedMedicine={selectedMedicine}
              medicineCompoundingFees={medicineCompoundingFees}
            />
          </Style.ModalRightBodyArea>
        </Style.ModalBodyArea>
      </Style.ModalArea>
    </BModal>
  );
}

export default MedicineUnitManagementModal;
