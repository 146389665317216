import usePharmacyInfo from '@mobile/api/hooks/usePharmacyInfo';
import { MobileLayout } from '@mobile/components/layout/MobileLayout';
import AuthError from '@mobile/pages/AuthError';
import Error from '@mobile/pages/Error';
import MobileAuth from '@mobile/pages/MobileAuth';

import * as ChannelService from '@channel.io/channel-web-sdk-loader';
import RequestList from '@mobile/pages/RequestList';
import TPAccept from '@mobile/pages/TPAccept';
import TPDetail from '@mobile/pages/TPDetail';
import TPReject from '@mobile/pages/TPReject';
import VerifyAuthentication from '@mobile/pages/VerifyAuthentication';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

function MobileRouter() {
  const { data: pharmacyInfo } = usePharmacyInfo();

  useEffect(() => {
    if (pharmacyInfo && pharmacyInfo.id && pharmacyInfo.pharmacy_name) {
      ChannelService.loadScript();

      ChannelService.boot({
        pluginKey: '175bf8ac-ec56-4b2e-8839-850301f472af',
        memberId: String(pharmacyInfo.id),
        profile: {
          name:
            pharmacyInfo.pharmacy_name && pharmacyInfo.id
              ? `${pharmacyInfo.pharmacy_name}(${pharmacyInfo.id})`
              : '',
          tags: ['pharmacist'],
        },
      });
    }
  }, [pharmacyInfo]);

  return (
    <MobileLayout>
      <Routes>
        <Route path="/" element={<MobileAuth />} />
        <Route path="/request" element={<RequestList />} />
        <Route path="/request/detail/:id" element={<TPDetail />} />
        <Route path="/request/accept/:id" element={<TPAccept />} />
        <Route path="/request/reject/:id" element={<TPReject />} />
        <Route path="/user-authentication" element={<VerifyAuthentication />} />
        <Route path="/auth-error" element={<AuthError />} />
        <Route path="/*" element={<Error />} />
      </Routes>
    </MobileLayout>
  );
}

export default MobileRouter;
