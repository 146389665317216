import BCarousel from '@components/meraki-ui/BCarousel';
import { myDoctorAPI } from '@services/myDoctor';
import { PageType } from '@utils/mixpanel/constants';
import { useState } from 'react';
import { useAsyncEffect } from 'rooks';

type PageParams = Exclude<
  PageType,
  'completeTreatment' | 'newReservationRequested'
>;

function AcceptedTelepharmacyCarousel({ pageType }: { pageType: PageParams }) {
  const [adList, setAdList] = useState<
    {
      imageUrl: string;
      linkUrl: string;
    }[]
  >([]);

  useAsyncEffect(async () => {
    const response = await myDoctorAPI.getConfig({
      key: 'PHARMACIST_PARTNER_PAGE_AD',
    });

    setAdList(JSON.parse(response?.data?.value).BANNER);
  }, []);

  return (
    <BCarousel
      width="100%"
      height={96}
      borderRadius={8}
      imageList={
        adList?.map((e) => {
          return {
            image: e.imageUrl,
            onClick: () => {
              if (!e?.linkUrl) return;
              window?.open(e.linkUrl, '_blank');
            },
          };
        }) || []
      }
      interval={6000}
    />
  );
}

export default AcceptedTelepharmacyCarousel;
